import React from 'react';

import addServerTimingHeader from '~/app/lib/utils/addServerTimingHeader';
import { AppPage, AppPageContext } from '~/app/components/NextApp/types';
import { fetchItemByPathAction } from '~/app/lib/store/paths/actions';
import { useAppLoading } from '~/app/components/NextApp/lib/CoreUi';
import PagePlaceholder from '~/app/components/PagePlaceholder';
import ItemPageAlbum from '~/app/pages/ItemPageAlbum';
import ItemPageTrack from '~/app/pages/ItemPageTrack';
import { useSelector } from '~/app/lib/store/redux';
import { useI18n, withI18n } from '~/app/lib/i18n';
import { State } from '~/app/lib/store/types';
import ApiError from '~/app/lib/errors/ApiError';
import { reportError } from '~/app/lib/sentry';
import { ItemTypes } from '~/types';

import {
  selectPathIsLoading,
  selectPathError,
  selectPathValue,
  selectItemByPathGlobal,
} from '~/app/lib/store/paths/selectors';
import { toDecodedItemPath } from '~/app/lib/router2/utils';

interface AlbumTrackNextPageProps {
  itemPath: string;
}

const AlbumTrackNextPage: AppPage<AlbumTrackNextPageProps> = ({
  itemPath,
}: AlbumTrackNextPageProps) => {
  const { t } = useI18n('app');

  const pathIsLoading = useSelector((state: State) =>
    selectPathIsLoading(state, itemPath)
  );

  // show the app global loading bar when item in 'loading' state
  useAppLoading()(pathIsLoading);

  const pathValue = useSelector((state: State) =>
    selectPathValue(state, itemPath)
  );

  const pathError = useSelector((state: State) =>
    selectPathError(state, itemPath)
  );

  if (!pathValue) {
    return (
      <PagePlaceholder
        isLoading={pathIsLoading}
        error={pathError}
        toErrorText={({ status }) => {
          if (status === 404) {
            return t('errors.item');
          }
        }}
      />
    );
  }

  switch (pathValue.type) {
    case ItemTypes.ALBUM:
      return <ItemPageAlbum albumId={pathValue.id} />;
    case ItemTypes.TRACK:
      return <ItemPageTrack trackId={pathValue.id} />;
    default:
      return null;
  }
};

AlbumTrackNextPage.getInitialProps = async (ctx) => {
  const {
    res,
    query,
    reduxStore: { dispatch, getState },
    asPath,
    pathname,
  } = ctx;

  // the fully DECODED path as stored in the songwhip-api database
  const itemPath = toDecodedItemPath(query);

  // fetch the Item by path
  const pendingFetch = dispatch(
    fetchItemByPathAction({
      path: itemPath,

      // only throw errors on serverside
      // to trigger try-catch below
      silent: !res,
    })
  );

  // is server
  if (!process.browser) {
    const timingStart = Date.now();

    const {
      serverRedirectIfNeeded,
    } = require('~/app/lib/router2/redirectIfNeeded/serverRedirectIfNeeded');

    if (res) {
      try {
        // SSR: block render until data fetched and report errors
        const result = await pendingFetch;

        if (result) {
          res.setHeader(
            'x-songwhip-resolve-path-from-api-cache',
            result.fromApiCache ? 'true' : 'false'
          );
        }
      } catch (error) {
        await onServerFetchError({ error, ctx });

        return {
          itemPath,
        };
      }

      const state = getState();
      const item = selectItemByPathGlobal(state, itemPath)!;

      addServerTimingHeader(
        res,
        'songwhip-web-ssr-resolve-path',
        Date.now() - timingStart
      );

      serverRedirectIfNeeded({
        res,
        state,
        expectedPagePath: item.pagePath,
        currentAsPath: asPath!,
        currentRoutePathname: pathname,
        pageOwnedByAccountIds: item.ownedByAccountIds,
      });
    }
  }

  return {
    itemPath,
  };
};

const onServerFetchError = async ({
  error,
  ctx,
}: {
  error: ApiError;
  ctx: AppPageContext;
}) => {
  const { res } = ctx;

  // add the correct status-code, this is super important as returning
  // a non-error code means that the response will be cached upstream
  if (res) {
    res.statusCode = error.status || 500;
  }

  // don't report 404s (too noisy)
  if (error.status === 404) {
    return;
  }

  await reportError({
    error,
    nextCtx: ctx,

    extras: {
      caughtAt: 'pages/[param1]/[param2]:onServerFetchError',
    },
  });
};

const AlbumTrackNextPageWithI18n = withI18n(AlbumTrackNextPage, [
  'item',
  'prerelease',
]);

export default AlbumTrackNextPageWithI18n;
